body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 17% !important;
}

@font-face {
  font-family: 'IBMPlexSansThai-Bold';
  src: local('IBMPlexSansThai-Bold'), url(./assets/fonts/IBMPlexSansThai/IBMPlexSansThai-Bold.woff2) format('woff2');
}
@font-face {
  font-family: 'IBMPlexSansThai-SemiBold';
  src: local('IBMPlexSansThai-SemiBold'), url(./assets/fonts/IBMPlexSansThai/IBMPlexSansThai-SemiBold.woff2) format('woff2');
}
@font-face {
  font-family: 'IBMPlexSansThai-Regular';
  src: local('IBMPlexSansThai-Regular'), url(./assets/fonts/IBMPlexSansThai/IBMPlexSansThai-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'IBMPlexSansThai-Text';
  src: local('IBMPlexSansThai-Text'), url(./assets/fonts/IBMPlexSansThai/IBMPlexSansThai-Text.woff2) format('woff2');
}
@font-face {
  font-family: 'IBMPlexSansThai-Medium';
  src: local('IBMPlexSansThai-Medium'), url(./assets/fonts/IBMPlexSansThai/IBMPlexSansThai-Medium.woff2) format('woff2');
}
@font-face {
  font-family: 'DBHelvethaicaX-Bold';
  src: local('DBHelvethaicaX-Bold'), url(./assets/fonts/DBHelvethaicaX/DBHelvethaicaX-Bold.woff2) format('woff2');
}
